<template>
  <div class="content-info">
    <h1>{{ content.title }}</h1>
    <p v-if="content.subtitle" class="subtitle">
      <strong>{{ content.subtitle }}</strong>
    </p>
    <div class="content-list-items">
      <p
        class="content-list"
        v-for="(item, index) in content.list"
        :key="index"
      >
        <img src="@/assets/img/ok-icon.png" class="ok-icon" />
        {{ item }}
      </p>
    </div>

    <img class="img" v-if="content.img" :src="content.img" />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  .content-list-items {
    margin: 14px auto 0 auto;
    display: flex;
    flex-direction: column;
    width: fit-content;

    p {
      margin-top: 0;
    }
  }
}
</style>

